import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { faUserPen } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { faTruckFast } from '@fortawesome/free-solid-svg-icons'

const Home = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    const checkEmail = () => {
        document.getElementById('emailForm').addEventListener('submit', function(event) {
            var email = document.getElementById('FormTextArea').value;
            if (!email || !validateEmail(email)) {
              event.preventDefault();
              alert('Please enter a valid email');
            }
          });
        };

    return (
<div className="container-fluid">

    <div className="hero-container">
        <div className="hero-column">
            <h1>Develop Professional Services Proposals Faster!<FontAwesomeIcon icon={faTruckFast} /></h1>
            <p>RedQuill is a Microsoft Word Add-In that uses AI to generate proposals for the architecture, engineering, and construction (A/E/C) industry hours faster than historical, manual methods.</p>
            <div>
                <form name="email-waitlist" method="post" className="form-inline" id="emailForm"  onSubmit={checkEmail} >
                    <input type="hidden" name="form-name" value="email-waitlist" />
                    <p>
                        <input name="email" type="email" className="form-control" id="FormTextArea" placeholder="Enter your email" rows="1"></input>
                    </p>
                    <p>
                        <button type="submit" id="WaitlistButton">Join our Email List!</button>
                    </p>
                </form>
            </div>
        </div>
    </div>

    <div className="benefits-container">
        <div className = "benefits">
            <h1>Faster First Drafts</h1>
            <p>Spend less time on monotonous writing tasks.<br/>RedQuill makes the once heavy lift feel feather light.</p>

            <div className="benefit-list">
                <FontAwesomeIcon icon={faWandMagicSparkles} />
                <h3>Your Personal AI Assistant</h3>
                <p>RedQuill learns from the solicitation document and your similar past proposals to assist with proposal development.</p>
            </div>

            <div className="benefit-list">
                <FontAwesomeIcon icon={faUserPen} />
                <h3>Save Hours on Writing</h3>
                <p>Get a slingshot start on writing, including technical sections, like methods.</p>
            </div>

            <div className="benefit-list">
                <FontAwesomeIcon icon={faLightbulb} />
                <h3>More Time for Refinement</h3>
                <p>Use your new found time to tailor your proposal with unique sales messages.</p>
            </div>
        </div>
    </div>

    <div className="process-container">
        <div className = "process">
            <h1>How to use RedQuill</h1>
            <p>Two simple steps to a faster proposal.</p>

            <div className="process-column">
                <h3>Step 1 - Upload the Solicitation Document & Similar Proposals</h3>
                <p>RedQuill will learn how to respond to the client's requirements based on the solicitation's scope of work and scoring criteria in combination with your past responses.</p>
            </div>

            <div className="process-column">
                <img src="uploadsScreen.png" alt="Upload Documents" />
            </div>

            <div className="process-column">
                <h3>Step 2 - Chat with your Personal Proposal Assistant</h3>
                <p>Ask RedQuill to generate sections of your proposal, like the outline, a first draft of the technical sections, or boilerplate text.</p>
            </div>

            <div className="process-column">
                <img src="authorScreen.png" alt="AI Author" />
            </div>
        </div>
    </div>

    <div className="final-plug-container">
        <div>
            <h2>Take the Pain out of Proposals</h2>
            <p>Submit your email to be notified when RedQuill is ready for you to use!</p>
            <button id="WaitlistButton-scroll" onClick={scrollToTop}>Notify me!</button>
        </div>
    </div>

    <div className="footer-container">
        <p>If you have any questions or thoughts, please reach out to us at <a href="mailto:info@redquill.io">info@redquill.io</a></p>
    </div>

</div>
    )
}

export default Home